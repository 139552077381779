import React from 'react';

export const onRouteUpdate = ({ location, prevLocation }) => {
    // if (!window.location.pathname.endsWith('/')) {
    //     window.location.pathname += '/';
    // }

    if(typeof window.firstparty !== 'undefined') {
        window.firstparty.page();
    }
}
